import React from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { useEffect } from "react";
import { usePostRequest } from "../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function ChangePassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const { createPost, isLoading, errorResp, success } = usePostRequest(
    "api/admin/change_password_no_auth"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const userEmail = sessionStorage.getItem("email");
    createPost({ ...data, email: userEmail });
    console.log(data);
  };

  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "Password changed!, Please login",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#08AC04",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
        }),
        100000
      );
      window.location.href = "/#/auth/sign-in";
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Box me="auto" w="100%">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Forget Password
          </Heading>
          <Text
            mb="6px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Change your password
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                id="password"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Password<Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="password"
                placeholder="****"
                mb="24px"
                fontWeight="500"
                size="lg"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <small className="text-primary-red text-xs">
                  Please enter your password
                </small>
              )}

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Confirm Password<Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                id="confirmpassword"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="password"
                placeholder="****"
                mb="24px"
                fontWeight="500"
                size="lg"
                {...register("confirmPassword", { required: true })}
              />
              {errors.confirmPassword && (
                <small className="text-primary-red text-xs">
                  Please enter your password
                </small>
              )}

              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? "Loading..." : "Change password"}
              </Button>
            </FormControl>
          </form>
        </Flex>
      </SimpleGrid>
    </DefaultAuth>
  );
}

export default ChangePassword;
