import React from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { useEffect } from "react";
import { usePostRequest } from "../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AllService from "../../../services/index";

function ForgetPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const [isVerify, setIsVerify] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");

  const { createPost, isLoading, errorResp, success } = usePostRequest(
    "api/admin/resend_otp"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setUserEmail(data.email);
    createPost(data);
  };
  const verifyCode = (data) => {
    const dataQ = {
      email: userEmail,
      verifyCode: data.verifyCode,
    };
    AllService.verifyUser(JSON.stringify(dataQ))
      .then((res) => {
        if (res.data.success) {
          setTimeout(
            Swal.fire({
              title: "Valid OTP",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
            }),
            100000
          );
          sessionStorage.setItem("email", userEmail);
          sessionStorage.setItem("authToken", res.data.token);
          window.location.href = "/auth/sign-in#/auth/change-password";
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    return;
  };
  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "Verification Code sent!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#08AC04",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
        }),
        100000
      );
      setIsVerify(true);
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Box me="auto" w="100%">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Forget Password
          </Heading>
          <Text
            mb="6px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your the 4 digit code sent to your email address
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          {isVerify ? (
            <form onSubmit={handleSubmit(verifyCode)}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Verification Code<Text color={"red"}>*</Text>
                </FormLabel>
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="****"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  {...register("verifyCode", { required: true })}
                />
                {errors.verifyCode && (
                  <small
                    className="text-primary-red text-xs"
                    style={{ color: "red" }}
                  >
                    Please enter your verification code
                  </small>
                )}

                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </Button>
              </FormControl>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={"red"}>*</Text>
                </FormLabel>
                <Input
                  variant="auth"
                  id="dskkdj"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="mail@korsgy.com"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <small className="text-primary-red text-xs">
                    Please enter your email
                  </small>
                )}

                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? "Loading..." : "Submit "}
                </Button>
              </FormControl>
            </form>
          )}
        </Flex>
      </SimpleGrid>
    </DefaultAuth>
  );
}

export default ForgetPassword;
