import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdAddTask, MdAttachMoney, MdBarChart } from "react-icons/md";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../careers/components/Spinner";
import AllService from "../../../services/index";
export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [tableData, setTableData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    AllService.getDashBoardData()
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
            gap="20px"
            mb="20px"
            mt="40px"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Contact us Messages"
              value={tableData.contactUsMessages}
              url="/admin/default#/admin/messages"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdAttachMoney}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Users"
              value={tableData.totalUsers}
              url="/admin/default#/admin/users"
            />
            <MiniStatistics
              name="Transactions"
              value={tableData.totalTransactions}
              url="/admin/default#/admin/transaction"
            />
            <MiniStatistics
              name="Admin"
              value={tableData.totalAdmin}
              url="/admin/default#/admin/user"
            />
            <MiniStatistics
              name="Frequently Asked Questions"
              value={tableData.totalFaq}
              url="/admin/default#/admin/faqs"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                  icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
                />
              }
              name="Suite"
              value={tableData.totalBusinessSuite}
              url="/admin/default#/admin/suite"
            />
          </SimpleGrid>
        </Box>
      )}
    </>
  );
}
