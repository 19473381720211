import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdMessage,
  MdPerson,
  MdPersonPin,
  MdHome,
  MdMailOutline,
  MdLock,
  MdAddCard,
  MdOutlineShoppingCart,
  MdRule,
  MdPassword,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Users from "views/admin/users";
import Careers from "views/admin/careers";
import Suite from "views/admin/suite";
import Faqs from "views/admin/faq";
import Transaction from "views/admin/transaction";
import Policy from "views/admin/policy";
import Messages from "views/admin/messages";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import forgetPassword from "views/auth/signIn/forget-password";
import changePassword from "views/auth/signIn/change-password";
let userRoute = {};
let passwordRoute = {};
let cpasswordRoute = {};

const user = sessionStorage.getItem("user");
if (!user) {
  userRoute = {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  };
  passwordRoute = {
    name: "Reset Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdPassword} width="20px" height="20px" color="inherit" />,
    component: forgetPassword,
  };
  cpasswordRoute = {
    name: "Change Password",
    layout: "/auth",
    path: "/change-password",
    icon: <Icon as={MdPassword} width="20px" height="20px" color="inherit" />,
    component: changePassword,
  };

}
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Users",
    layout: "/admin",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: "/users",
    component: Users,
  },
  {
    name: "Career",
    layout: "/admin",
    icon: <Icon as={MdPersonPin} width="20px" height="20px" color="inherit" />,
    path: "/careers",
    component: Careers,
  },
  {
    name: "Suite",
    layout: "/admin",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/suite",
    component: Suite,
  },
  {
    name: "FAQ",
    layout: "/admin",
    icon: <Icon as={MdMessage} width="20px" height="20px" color="inherit" />,
    path: "/faqs",
    component: Faqs,
  },
  {
    name: "Transaction",
    layout: "/admin",
    icon: <Icon as={MdAddCard} width="20px" height="20px" color="inherit" />,
    path: "/transaction",
    component: Transaction,
  },
  {
    name: "Messages",
    layout: "/admin",
    icon: <Icon as={MdMailOutline} width="20px" height="20px" color="inherit" />,
    path: "/messages",
    component: Messages,
  },
  {
    name: "Policy",
    layout: "/admin",
    icon: <Icon as={MdRule} width="20px" height="20px" color="inherit" />,
    path: "/policy",
    component: Policy,
  },
  userRoute,
  passwordRoute,
  cpasswordRoute
];

export default routes;
