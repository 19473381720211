import {
  Flex,
  Text,
  useColorModeValue,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Select,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import qs from "qs";
import axios from "axios";
import parse from "html-react-parser";
import Swal from "sweetalert2";
// Custom components
import Card from "components/card/Card";

export default function ColumnsTable(props) {
  const [detail, setDetail] = useState("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/api/admin/get_policy?key=${category}`;
    try {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url,
      };
      axios(options).then((item) => {
        setLoading(false);
        setDetail(item.data.data.details);
      });
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  }, [category]);

  //the alert component
  const addContent = () => {
    setLoading(true);
    const formData = {
      details: detail,
      category: category,
    };
    const url = `${process.env.REACT_APP_API_URL}/api/admin/add_policy`;
    try {
      const options = {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: sessionStorage.getItem("authToken"),
        },
        data: qs.stringify(formData),
        url,
      };
      axios(options).then(() => {
        setLoading(false);
        Swal.fire({
          title: "Policy Updated Successfully!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#87AA62",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
          backdrop: `
              #87AA62
              left top
              no-repeat
            `,
        });
      });
    } catch (e) {
      setLoading(false);
      Swal.fire({
        title: "Error!",
        text: e.message,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* add a new SUITE */}
      <Card
        direction="column"
        my="60px"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        style={{ padding: "40px 90px", alignContent: "center" }}
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="70px">
            Update Policy
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w="100%"
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Select Category<Text color={"red"}>*</Text>
              </FormLabel>
              <Select
                variant="subtle"
                placeholder="Select"
                mb="16px"
                fontSize="sm"
                defaultValue=""
                width="unset"
                fontWeight="700"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="privacynotice" className="font-medium">
                  Privacy Notice
                </option>
                <option value="cookiespreferences" className="font-medium">
                  Cookies Preferences
                </option>
                <option value="termsofservice" className="font-medium">
                  Terms of Services
                </option>
              </Select>
              {/* {errors.category && (
                <small className="text-red text-xs" style={{ color: "red" }}>
                  Please Select a category
                </small>
              )} */}
              <Editor
                apiKey={process.env.REACT_APP_TINY}
                initialValue={detail}
                init={{
                  height: 500,
                  menubar: "insert",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "image |insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                }}
                onChange={(e, editor) => {
                  const data = e.target.getContent();
                  setDetail(data);
                }}
              />

              {category !== "" ? (
                <Button
                  fontSize="sm"
                  colorScheme="green"
                  fontWeight="500"
                  w="30%"
                  mb="24px"
                  type="submit"
                  disabled={loading ? true : false}
                  onClick={addContent}
                >
                  {loading ? `Updating the ${category}` : `Update ${category}`}
                </Button>
              ) : (
                <></>
              )}
            </FormControl>
          </form>
        </Flex>
      </Card>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <article className="sm:text-sm md:text-base lg:text-lg leading-8 my-5">
          <p>{parse(`${detail}`)}</p>
        </article>
      </Card>
    </>
  );
}
