import http from "./http-common";
let token = sessionStorage.getItem("authToken");

const getJob = (data) => {
  return http.get(`api/career/get/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const endJob = (data) => {
  return http.post(`api/career/end/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const deleteJob = (data) => {
  return http.delete(`api/career/delete/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const deleteMessage = (data) => {
  return http.delete(`/api/admin/contact_us/delete/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const getDashBoardData = () => {
  return http.get("api/admin/summary", {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const getAllUser = (page = 1, limit = 10) => {
  return http.get(`api/admin/users?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const getAllTransaction = () => {
  return http.get(`api/admin/transaction`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const deleteFaq = (data) => {
  return http.delete(`api/faq/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const deleteSuit = (data) => {
  return http.delete(`api/suite/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const verifyUser = (data) => {
  return http.post("api/admin/verify_email", data);
};


const logout = (data) => {
  return http.delete(`api/admin/logout`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const exportedObject = {
  endJob,
  deleteFaq,
  deleteSuit,
  logout,
  getDashBoardData,
  getAllUser,
  getAllTransaction,
  getJob,
  verifyUser,
  deleteMessage,
  deleteJob
};

export default exportedObject;
