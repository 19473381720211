import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useEffect } from "react";
import { usePostRequest } from '../../../mutation';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'

function SignIn() {
  const user = sessionStorage.getItem("user")
  if(user)
  window.location.href = "/admin/default#/admin/default"
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { createPost, isLoading, errorResp, success } =
    usePostRequest("api/admin/login");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => {
    createPost(data);
    // console.log(data)
  }

  useEffect(() => {
    if (success) {
      sessionStorage.setItem("authToken", success.token);
      sessionStorage.setItem("user", JSON.stringify(success.admin));
      setTimeout(
        Swal.fire({
          title: 'Login Successful!',
          width: 600,
          padding: '3em',
          icon: 'success',
          color: '#08AC04',
          background: '#fff ',
          confirmButtonColor: '#08AC04',
          backdrop: `
              #08AC04
              left top
              no-repeat
            `
        }), 100000);
      window.location.href = "/admin/default#/admin/default";
    }
    if (errorResp) {
      Swal.fire({
        title: 'Error!',
        text: errorResp.errorResponse,
        icon: 'error',
        confirmButtonText: 'ok',
        confirmButtonColor: '#08AC04',
      })
    }
  }, [success, errorResp]);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Box me="auto" w="100%">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                id="email"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@korsgy.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <small className="text-primary-red text-xs">
                  Please enter your email
                </small>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={"red"}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  id="password"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <small className="text-primary-red text-xs">
                    Please enter your password
                  </small>
                )}
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                <NavLink to="/auth/forgot-password">
                  <Text
                    color={textColorBrand}
                    fontSize="sm"
                    w="124px"
                    fontWeight="500"
                  >
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? "Loading..." : "Sign In"}
              </Button>
            </FormControl>
          </form>
        </Flex>
      </SimpleGrid>
    </DefaultAuth>
  );
}

export default SignIn;
