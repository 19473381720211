import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "views/admin/transaction/components/ComplexTable";
import StripeTransaction from "views/admin/transaction/components/StripeTransaction";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../careers/components/Spinner";
import AllService from "../../../services/index";
const stripe = require("stripe")(process.env.REACT_APP_STRIPE_SK);

export default function Settings() {
  const user = sessionStorage.getItem("user");
  if (!user) window.location.href = "/admin/default#/auth/sign-in";

  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [stripeTrxn, setStripeTrxn] = React.useState([]);

  //GET STRIPE TRANSACTIONS
  const stripeTransactions = async () => {
    const payments = await stripe.paymentIntents.list({
      limit: 100,
    });
    let arr = payments.data.filter((item) => item.status === "succeeded");
    setStripeTrxn(arr);
  };

  useEffect(() => {
    stripeTransactions();
    AllService.getAllTransaction()
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
  }, []);
  const tableheader = [
    {
      Header: "fullname",
      accessor: "fullname",
    },
    {
      Header: "amount",
      accessor: "amount",
    },
    {
      Header: "email",
      accessor: "email",
    },
    {
      Header: "cart",
      accessor: "newCart",
    },
    {
      Header: "state",
      accessor: "state",
    },
    {
      Header: "dateCreated",
      accessor: "dateCreated",
    },
  ];

  const tableheaderStripe = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "amount",
      accessor: "amount",
    },
    {
      Header: "status",
      accessor: "status",
    },
    {
      Header: "metadata.cart",
      accessor: "metadata.cart",
    },

    {
      Header: "created",
      accessor: "created",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
              mb="20px"
              columns={{ sm: 1, md: 1 }}
              spacing={{ base: "20px", xl: "20px" }}
            >
              <ComplexTable columnsData={tableheader} tableData={tableData} />
            </SimpleGrid>
          </Box>
          <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
              mb="20px"
              columns={{ sm: 1, md: 1 }}
              spacing={{ base: "20px", xl: "20px" }}
            >
              <StripeTransaction
                columnsData={tableheaderStripe}
                tableData={stripeTrxn}
              />
            </SimpleGrid>
          </Box>
        </>
      )}
    </>
  );
}
