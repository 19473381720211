import {
  Flex,
  Table,
  Button,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Box,
  Textarea,
  Select
} from "@chakra-ui/react";

import React, { useMemo, useRef, useEffect, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import parse from "html-react-parser";

// Custom components
import Card from "components/card/Card";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";

import { usePostRequest } from "../../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import Pagination from "../../../../components/Pagination";
import { utils, write } from 'xlsx';
import FileSaver from 'file-saver';
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";

export default function ColumnsTable(props) {

  const [searchText, setSearchText] = useState("Select Job");
  const exportToExcel = (dataArray, fileName) => {
    const worksheet = utils.json_to_sheet(dataArray);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(data, fileName + '.xlsx');
  };

  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex, }, //pageSize
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const handleExport = () => {
    if (!searchText) {
      return Swal.fire({
        title: "Error!",
        text: "Please select a job to export the application ",
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
    AllService.getJob(searchText)
      .then((res) => {
        if (res.data.success) {
          exportToExcel(res.data.data.applicants, res.data.data.title);
          setTimeout(
            Swal.fire({
              title: "Application Exported",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
            }),
            100000
          );
        }
      })
      .catch((e) => {

        Swal.fire({
          title: "Error!",
          text: "An error occured: " + JSON.stringify(e),
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
  };
  //the alert component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [selectedId, setSelectedId] = useState(null);
  const [description, setDescription] = useState("")
  const { createPost, isLoading, errorResp, success } =
    usePostRequest("api/career/add");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let questions = []
    for (const key in data) {
      if (key.slice(0, 3) === "qtn") {
        questions.push(data[key])
        delete data[key]
      }

    }
    data.questionsArray = questions
    const datas = { ...data, description }
    createPost(datas);
  };
  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "New job created!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#87AA62",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
          backdrop: `
              #87AA62
              left top
              no-repeat
            `,
        }),
        100000
      );
      window.location.reload();
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);
  const endJob = () => {
    AllService.endJob(selectedId)
      .then((res) => {
        if (res.data.success) {
          console.log(res);
          setTimeout(
            Swal.fire({
              title: "Application ended",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    onClose();
  };
  const deleteJob = () => {
    AllService.deleteJob(selectedId)
      .then((res) => {
        if (res.data.success) {
          console.log(res);
          setTimeout(
            Swal.fire({
              title: "Application Deleted",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e)
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    onClose();
  };

  const [values, setValues] = useState([]);

  function addInputBox() {
    setValues([...values, '']);
  }
  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            All Careers
          </Text>
          <Select
            variant="subtle"
            placeholder="Select"
            mb="16px"
            fontSize="sm"
            defaultValue=""
            width="unset"
            fontWeight="700"
            onChange={(e) => setSearchText(e.target.value)}
          >
            {
              tableData.map(data => {
                return (
                  <option value={data._id}>{data.title}</option>
                )
              })
            }
          </Select>
          <Button
            fontSize="sm"
            colorScheme="green"
            fontWeight="500"
            w="50"
            h="50"
            mb="24px"
            type="button"
            onClick={handleExport}
          >
            Export Data
          </Button>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "title") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "status") {
                      data = (
                        <Flex align="center">
                          <Icon
                            w="24px"
                            h="24px"
                            me="5px"
                            color={
                              cell.value === true ? "green.500" : "red.500"
                            }
                            as={cell.value === true ? MdCheckCircle : MdCancel}
                          />
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value === true ? "Open" : "Closed"}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "code") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "TotalApplicant") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value.length}
                        </Text>
                      );
                    } else if (cell.column.Header === "createdBy") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "created") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">

                          {moment(cell.value).fromNow()}
                        </Text>
                      );
                    } else if (cell.column.Header === "more") {
                      data = (
                        <>
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {/* {cell.value} */}
                            <Icon
                              onClick={() => {
                                setSelectedId(cell.value);
                                onOpen();
                              }}
                              w="24px"
                              h="24px"
                              me="5px"
                              color={"yellow.400"}
                              as={MdOutlineError}
                            />
                          </Text>
                          {/* Dialog box */}
                          <AlertDialog
                            isOpen={isOpen}
                            leastDestructiveRef={cancelRef}
                            onClose={onClose}
                          >
                            <AlertDialogOverlay>
                              <AlertDialogContent>
                                <AlertDialogHeader
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  End/Delete this offer
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                  Are you sure? You can't undo this action
                                  afterwards.
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                  <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                  </Button>
                                  <Button
                                    colorScheme="red"
                                    onClick={deleteJob}
                                    ml={3}
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    colorScheme="blue"
                                    onClick={endJob}
                                    ml={3}
                                  >
                                    End
                                  </Button>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialogOverlay>
                          </AlertDialog>
                        </>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>
      {/* add a new career */}
      <Card
        direction="column"
        my="60px"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        style={{ padding: "40px 90px", alignContent: "center" }}
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="70px">
            Add A New Job Offer
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w="100%"
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Title<Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="enter title"
                mb="24px"
                fontWeight="500"
                size="lg"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <small className="text-primary-red text-xs">
                  Please the Job Title
                </small>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Description<Text color={"red"}>*</Text>
                <p>Please copy and use this as bullet points and add 3 spaces before the content (♦)</p>
              </FormLabel>
              <Editor
                apiKey={process.env.REACT_APP_TINY}
                initialValue={"Enter Description"}
                init={{
                  height: 500,
                  menubar: "insert",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "image |insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                }}
                onChange={(e, editor) => {
                  const data = e.target.getContent();
                  setDescription(data);
                }}
              />

              {errors.description && (
                <small className="text-primary-red text-xs">
                  Please the Job Description
                </small>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                JobCode<Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="xxxx"
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("jobCode", { required: true })}
              />
              {errors.jobCode && (
                <small className="text-primary-red text-xs">
                  Please the unique code of this offer
                </small>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Job Type (Please, seperate each location with comma and no
                spaces e.g Remote,Onsite)<Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Remote,Onsite etc"
                mb="24px"
                fontWeight="500"
                size="lg"
                {...register("jobType", { required: true })}
              />
              {errors.title && (
                <small className="text-primary-red text-xs">
                  Please enter the Job Type
                </small>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Location (Please, seperate each location with comma and no
                spaces e.g Portugal,Canada)<Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Portugal,Canada etc"
                mb="24px"
                fontWeight="500"
                size="lg"
                {...register("location", { required: true })}
              />
              {errors.title && (
                <small className="text-primary-red text-xs">
                  Please the Job Title
                </small>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Question
              </FormLabel>
              {values.map((value, index) => (
                <Input
                  key={index}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  {...register(`qtn${index + 1}`, { required: true })}
                />
              ))}
              <Button
                fontSize="sm"
                colorScheme="green"
                fontWeight="500"
                w="20%"
                h="50"
                mb="24px"
                type="submit"
                onClick={addInputBox}
              >
                Add question
              </Button>
              <Button
                fontSize="sm"
                colorScheme="green"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? "Adding.." : "Post Job"}
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Card>
      <Card direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}

      >
        <h4 style={{ marginBottom: "2rem", textAlign: "center" }}>Formatted Job Description</h4>
        <article className="sm:text-sm md:text-base lg:text-lg leading-8 my-5 px-8">
          <p>{parse(`${description}`)}</p>
        </article></Card>
    </>
  );
}
